import React from 'react';
import { colors } from 'config/colors';

export const PrimaryButtonIcon = ({ text, onClick, icon, textColor }) => {

  const styles = {
    container: {
      backgroundColor: colors[process.env.REACT_APP_ZONE].medium,
      fontWeight: '600',
      fontSize: '3.2vw',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: textColor ? textColor : 'white'
    },
  };

  return(
    <div style={styles.container} className="p-4 py-3 m-0" onClick={onClick}>
      <span>
        {text}
      </span>
      {icon}
    </div>
  );
};