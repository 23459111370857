import React from 'react';

export const LoadingModal = ({ isLoading }) => {

  const styles = {
    container: {
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      top: 0,
      left: 0,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(250,250,250, 0.93)',
      zIndex: 10
    },
    image: {
      transform: 'translateY(-100%)'
    }
  }

  return(
    isLoading &&
      <div style={styles.container}>
        <img alt="logo-lokk-loading" style={styles.image} src="/Loading_Logo_Simple.webp" height="150" width="150"></img>
      </div>
  );
};
