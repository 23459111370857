import React from 'react';
import { LockerIcon } from 'components/common/LockerIcon';
import { ReactComponent as Helmet } from 'assets/images/icons/v2/Helmet.svg';
import { ReactComponent as Bicicle } from 'assets/images/icons/v2/Bicicle.svg';
import { ReactComponent as Scooter } from 'assets/images/icons/v2/Scooter.svg';
import LockerSizesIcon from 'components/common/LockerSizesIcon'
import LockerIconCold from 'components/common/LockerIconCold'

const styles = {
  container: {
    backgroundColor: 'transparent',
  },
  text: {
    fontSize: '3vw',
    fontWeight: '600'
  }
};

export const LockerInfo = ({ text, number, size, type, zone, accessibility }) => {
  const getIcon = (zone, size) => {
    if(type){
      return <LockerIconCold size="40" />
    }else{
      switch (zone) {
        case 'red-zone':
          if(size === 'S') return 'S'
          if(size === 'M') return 'M'
          if(size === 'L') return 'L'
          if(size === 'XL') return 'XL'
          //return <LockerSizesIcon sHeight="30" mHeight="40" lHeight="50" xlHeight="60" size={size} />
        case 'green-zone':
            if(size === 'S') return <Helmet height="40" width="40" />
            if(size === 'M') return <Scooter height="40" width="40" />
            if(size === 'L') return <Bicicle height="40" width="40" />
          break;
        default:
          break;
      }
    }
  }

  return(
    <div style={styles.container} className="p-4">
      <div className="py-4 my-4">
        <LockerIcon lockerNumber={number} size={size} icon={getIcon(zone, size)}/>
      </div>
      <div className="py-4 my-4"><span style={styles.text}>{text}</span></div>
    </div>
  );
};