import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { LockerInfo } from 'components/common/LockerInfo';
import { useIntl } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { PrimaryButton } from 'components/common/PrimaryButton';
import { FormattedMessage } from 'react-intl';
import './animation.css';
import api from 'helpers/api';
import { LoadingModal } from 'components/common/LoadingModal';

export const GreenZoneCollectObject = () => {
  const t = useIntl();
  let navigate = useNavigate();
  const { state } = useLocation();

  const [message, setMessage] = useState('app.objectToBeCollected')
  const [animBtn, setAnimBtn] = useState('');
  const [animRow, setAnimRow] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openLocker = () => {
    setIsSubmitting(true);
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/collectDeliveryWithPin?pin=${state.pin}`)
      .then(function (response) {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          console.log(response?.data?.Data?.assignedLocker);
          setMessage('app.lockerHasBeenOpened');
          setAnimBtn('fadeOutButton');
          setAnimRow('fadeOutRow');
        } else {
          console.log('Unknow Error');
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setIsSubmitting(false);
      });
  }

  let { transitionType } = useRouterTransition(navigate);

  return(
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-center position-relative">
      <LoadingModal isLoading={loading} />
      <Row className={`${animRow} m-0`} style={{marginTop: '-100px'}}>
        <LockerInfo text={<FormattedMessage id={message} values={{ lokkerNum: state?.locker?.locker_metra_id }}/>} number={state?.locker?.locker_metra_id} size={state?.locker?.size} type={0} />
        <Col xs="12" className={`${animBtn} p-0`}>
          <PrimaryButton 
            text={t.messages['app.openLocker']} 
            color="yellow" 
            onClick={isSubmitting ? () => null : openLocker} 
            isEnabled={!isSubmitting}
          />
        </Col>
      </Row>
    </TransitionView>
  );
}