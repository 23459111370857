import { useState } from 'react';
import { routerTransitionDelay } from 'config/appConfig';
import { useDispatch } from 'react-redux';

export const useRouterTransition = (navigate) => {
  const dispatch = useDispatch()
  const [transitionType, setTransitionType] = useState('in');

  const handleTransition = (path, state) => {
    setTransitionType('out');

    setTimeout(() => {
      if(path !== '/'){
        navigate(path, state)
        dispatch({type: 'SET_HOMEPAGE', payload: false})
      }else{
        navigate('/', state)
        dispatch({type: 'SET_HOMEPAGE', payload: true})
      }
    }, routerTransitionDelay * 300);
  }

  return { transitionType, handleTransition };
};
