import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useNavigate } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { LoadingModal } from 'components/common/LoadingModal';
import { SecondaryNav } from 'components/common/SecondaryNav';
import { colors } from 'config/colors'
import { Check, ChevronDown, ChevronUp } from 'react-feather'
import { PrimaryButton } from 'components/common/PrimaryButton';
import api from 'helpers/api';
import { useSelector } from 'react-redux';

export const GreenZoneTermsAndConditions = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);
  const language = useSelector(state => state.language);
  const contentRef = useRef(null);

  const [checked, setChecked] = useState(false);
  const [text, setText] = useState(false);

  const [loading, setLoading] = useState(false);

  const navTo = (url, state) => {
    handleTransition(url, state)
  };

  const choiceText = (text) => {
    if (language === 'pt-PT') {
      setText(text.pt)
    }
    else if (language === 'en-US') {
      setText(text.en)
    }
    else if (language === 'es-ES') {
      setText(text.es)
    }
    else {
      setText(text.pt)
    }
  }

  const scrollToTop = () => {
    if (contentRef.current) {
      const scrollStep = -200;
      contentRef.current.scrollTop += scrollStep;
    }
  };

  const scrollToBottom = () => {
    if (contentRef.current) {
      const scrollStep = 200;
      contentRef.current.scrollTop += scrollStep;
    }
  };

  useEffect(() => {
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/text/list?type=terms-conditions`)
      .then(function (response) {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          choiceText(response?.data?.Data?.text)
        } else {
          console.log(response?.data?.Result);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);


  useEffect(() => {
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/text/list?type=terms-conditions`)
      .then(function (response) {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          choiceText(response?.data?.Data?.text)
        } else {
          console.log(response?.data?.Result);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [language]);

  return (
    text &&
    <TransitionView animation="fade-down" transitionType={transitionType} className="position-relative px-5">
      <LoadingModal isLoading={loading} />
      <SecondaryNav secNavText="app.termsAndConditions" navToURL="/" />
      <Col xs="12" className="d-flex justify-content-center">
        <div className="d-flex justify-content-center align-items-center" onClick={scrollToTop} style={{ height: '5vw', width: '10vw', backgroundColor: colors[process.env.REACT_APP_ZONE].medium, color: 'white' }}>
          <ChevronUp size="4vw"/>
        </div>
      </Col>
      <Col xs="12" className="ps-1 py-2 noScroll" style={{ height: '70%', overflowY: 'scroll', border: `2px solid ${colors['grey'].medium}` }}>
        <div
          className="position-relative p-3 scroll"
          style={{
            height: '100%',
            overflow: 'auto'
          }}
          ref={contentRef}
        >
          <div className="d-flex justify-content-start pt-3 pb-5">
            <img alt="mar-shopping" height="75vw" src="ingka-terms-conditions.png" />
          </div>
          
          <span style={{ fontSize: '2.2vw', textAlign: 'justify', textJustify: 'inter-word' }}>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </span>
        </div>
      </Col>
      <Col xs="12" className="d-flex justify-content-center">
        <div className="d-flex justify-content-center align-items-center" onClick={scrollToBottom} style={{ height: '5vw', width: '10vw', backgroundColor: colors[process.env.REACT_APP_ZONE].medium, color: 'white' }}>
          <ChevronDown size="4vw"/>
        </div>
      </Col>
      <Col style={{ height: '10%' }}>
        <Row className="py-5 d-flex justify-content-between align-items-center">
          <Col xs="2">
            <div
              className="d-flex justify-content-center align-items-center"
              onClick={() => setChecked(!checked)}
              style={{
                height: '90px',
                width: '90px',
                border: `4px solid ${colors[process.env.REACT_APP_ZONE].medium}`,
              }}
            >
              {checked && <Check size="70px" />}
            </div>
          </Col>
          <Col xs="10" className="d-flex justify-content-start">
            <span style={{ fontSize: '2vw', fontWeight: '700' }}>
              {t.messages['app.acceptTermsAndConditions']}
            </span>
          </Col>
        </Row>
      </Col>
      <Col xs="12" className="d-flex align-items-center" style={{ height: '10%' }}>
        <Row className="w-100 m-0">
          <PrimaryButton txtColor="light" text={t.messages['app.confirm']} onClick={() => navTo('/lokker-size', { state: { type: 0, accessibility: 0, checked: checked } })} isEnabled={checked} />
        </Row>
      </Col>
    </TransitionView>
  )
}