import React from 'react';
import { colors } from 'config/colors';

export const SquareIconButton = ({ icon, text, onClick, size, homepage }) => {

  const styles = {
    button: {
      height: size ? size : '150px',
      width: size ? size : '150px',
      backgroundColor: 'transparent',
      borderRadius: '20px',
      border: `none`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px 20px',
    },
    text: {
      fontSize: '2.5vw'
    }
  }

  return(
    <button style={styles.button} onClick={onClick}>
      <span>{icon}</span>
     {text !== undefined && 
      <span style={{ fontSize: '2.5vw', color: homepage ? colors.white : colors['grey'].medium }} className="mt-2">
        {text}
      </span>}
    </button>
  )
}