import React from 'react'
import { Row, Col } from 'reactstrap'
import { ReactComponent as ThermalCold } from 'assets/images/icons/v2/ThermalCold.svg';
import { ReactComponent as ThermalHot } from 'assets/images/icons/v2/ThermalHot.svg';

const LockerIconCold = ({ size }) => {
  return (
    <Row >
      <Col md="6" className="pr-1">
        <ThermalHot width={size} height={size} />
      </Col>
      <Col md="6" className="px-0">
        <ThermalCold width={size} height={size} />
      </Col>
    </Row>
  )
}

export default LockerIconCold