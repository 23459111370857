import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { MenuButton } from 'components/common/MenuButton';
import { ReactComponent as Carteira } from 'assets/images/icons/v1/Carteira.svg';
import { ReactComponent as Mochila } from 'assets/images/icons/v1/Mochila.svg';
import { ReactComponent as Portatil } from 'assets/images/icons/v1/Portatil.svg';
import { ReactComponent as Capacete } from 'assets/images/icons/v1/Capacete.svg';
import { ReactComponent as Saco } from 'assets/images/icons/v1/Saco.svg';
import { ReactComponent as Malinha } from 'assets/images/icons/v1/Malinha.svg';
import { ReactComponent as Compras } from 'assets/images/icons/v1/Compras.svg';
import { ReactComponent as Mala } from 'assets/images/icons/v1/Mala.svg';
import { ReactComponent as Encomendas } from 'assets/images/icons/v1/Encomendas.svg';
import api from 'helpers/api';
import { LoadingModal } from 'components/common/LoadingModal';
import { SecondaryNav } from 'components/common/SecondaryNav';

export const GreenZoneDepositSize = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);

  const [lockers, setLockers] = useState('');
  const [loading, setLoading] = useState(true);

  const navTo = (url, state) =>{
    handleTransition (url, state)
  };

  const normalSizeSLockersAvailable = () => {
    return Object.keys(lockers)?.filter(function (key) {
      return lockers[key].enabled === 1 && lockers[key].status === 0 && lockers[key].locker_metra_status === 2 && lockers[key].size === 'S';
    });
  };

  const normalSizeMLockersAvailable = () => {
    return Object.keys(lockers)?.filter(function (key) {
      return lockers[key].enabled === 1 && lockers[key].status === 0 && lockers[key].locker_metra_status === 2  && lockers[key].size === 'M';
    });
  };

  const normalSizeLLockersAvailable = () => {
    return Object.keys(lockers)?.filter(function (key) {
      return lockers[key].enabled === 1 && lockers[key].status === 0 && lockers[key].locker_metra_status === 2 && lockers[key].size === 'L';
    });
  };

  useEffect(() => {
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/lockersByPlace/${process.env.REACT_APP_LOCATION_ID}`)
      .then(function (response) {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          setLockers(response?.data?.Data?.Lockers)
        } else {
          console.log(response?.data?.Result);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  },[]);

  return(
    <TransitionView animation="fade-down" transitionType={transitionType}>
      <LoadingModal isLoading={loading} />
      <SecondaryNav  secNavtext="app.pickLockerSize" navToURL="/pick-and-collect"/>

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.sizeS']}
          subtext={`${normalSizeSLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={<Row>
            <Col xs="4"><Carteira height="26" width="26"/></Col>
            <Col xs="4"><Malinha height="26" width="26"/></Col>
            <Col xs="4"><Portatil height="26" width="26"/></Col>
          </Row>}
          onClick={() => navTo('/deposit-create-pin', 'S')}
          disabled={normalSizeSLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.sizeM']}
          subtext={`${normalSizeMLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={<Row>
            <Col xs="4"><Capacete height="30" width="30"/></Col>
            <Col xs="4"><Saco height="30" width="30"/></Col>
            <Col xs="4"><Mochila height="30" width="30"/></Col>
          </Row>}
          onClick={() => navTo('/deposit-create-pin', 'M')}
          disabled={normalSizeMLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.sizeL']}
          subtext={`${normalSizeLLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={<Row>
            <Col xs="4"><Compras height="34" width="34"/></Col>
            <Col xs="4"><Mala height="34" width="34"/></Col>
            <Col xs="4"><Encomendas height="34" width="34"/></Col>
          </Row>}
          onClick={() => navTo('/deposit-create-pin', 'L')}
          disabled={normalSizeLLockersAvailable().length !== 0 ? false : true}
        />
      </Row>
    </TransitionView>
  )
}