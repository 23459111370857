import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
} from 'reactstrap';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { Numpad } from 'components/common/numpad/Numpad';
import { Input } from 'components/common/numpad/Input';
import { useIntl } from 'react-intl';
import { PrimaryButton } from 'components/common/PrimaryButton';
import api from 'helpers/api';
import { LoadingModal } from 'components/common/LoadingModal';
import { colors } from 'config/colors';
import { countryCodes } from 'config/numberPrefix';
import SelectCountryPrefix from 'components/common/SelectCountryPrefix';

export const GreenZoneCreatePin = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { state } = useLocation();
  let { transitionType, handleTransition } = useRouterTransition(navigate);

  const [selectedInput, setSelectedInput] = useState('phoneNumber');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pin, setPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [pinAnimation, setPinAnimation] = useState('');
  const [confirmPinAnimation, setConfirmPinAnimation] = useState('');
  const [phoneAlreadyInUse, setPhoneAlreadyInUse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorNumberPrefix, setErrorNumberPrefix] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countryCodes[0]);
  const [phoneNumberSize, setPhoneNumberSize] = useState(selectedCountry.characters);

  const numbers = ['1', '2', '3', '6'];

  const pinSize = 4;
  const invalidInputAnimationDuration = 0.7;

  const handleChangePin = (val) => {
    // delete last char from pin
    if (val === '#') {
      if (pin.length === 0) setSelectedInput('phoneNumber');
      else setPin(pin.slice(0, -1));
    }
    // add char to pin
    // if last char also go to next input
    else if (pin.length === pinSize - 1) {
      setPin(pin + val);
      setSelectedInput('confirmPin');
    }
    else if (pin.length < pinSize) setPin(pin + val);
    else if (pin.length === pinSize) setSelectedInput('confirmPin');
  };

  const handleChangeConfirmPin = (val) => {
    // delete last char from confirmPin
    if (val === '#') {
      if (confirmPin.length === 0) setSelectedInput('pin');
      else setConfirmPin(confirmPin.slice(0, -1));
    }
    // add char to confirmPin
    else if (confirmPin.length < pinSize) setConfirmPin(confirmPin + val);
  };

  const navTo = (url, state) => {
    handleTransition (url, state)
  };

  const handleNumpadInput = (val) => {
    // when user presses asterisk clear everything
    if (val === '*') {
      setPhoneNumber('');
      setPin('');
      setConfirmPin('');
      setSelectedInput('phoneNumber');
    }
    else {
      // handle specific input
      switch (selectedInput) {
        case 'phoneNumber':
          handleChangePhoneNumber(val)
          break;
        case 'pin':
          handleChangePin(val)
          break;
        case 'confirmPin':
          handleChangeConfirmPin(val)
          break;
        default:
          break;
      }
    }
  };

  const isPhoneNumberValid = () => {
    
    const pattern = new RegExp(`^\\d{${phoneNumberSize}}$`);

    if (phoneNumber.match(pattern)) {
      if(selectedCountry.name === 'Portugal' && (phoneNumber.charAt(0) !== '9' || !numbers.includes(phoneNumber.charAt(1)))){
        // is invalid
        return false;
      }else{
        // is valid
        return true;
      }
    }
    return false
  };

  const isPinValid = () => {
    const pattern = new RegExp(`^\\d{${pinSize}}$`);
    if (pin.match(pattern)) {
      // is valid
      return true;
    }
  };

  const isConfirmPinValid = () => {
    const pattern = new RegExp(`^\\d{${pinSize}}$`);
    if (confirmPin.match(pattern) && confirmPin === pin) {
      // is valid
      return true;
    }
  };

  const validateInput = () => {
    if (phoneAlreadyInUse === false) {
      if (isPhoneNumberValid() && isPinValid() && isConfirmPinValid() && pin === confirmPin) {
        // input is valid
        return true;
      } else {
        // input is invalid
        return false;
      }
    } else {
      if (isPhoneNumberValid() && isPinValid()) {
        // input is valid
        return true;
      } else {
        // input is invalid
        return false;
      }
    }
  };

  const handleChangePhoneNumber = (val) => {
    // delete last char from phoneNumber
    if (val === '#') setPhoneNumber(phoneNumber.slice(0, -1));
    // add char to phoneNumber
    // if last char also go to next input
    else if (phoneNumber.length === phoneNumberSize - 1) {
      setPhoneNumber(phoneNumber + val);

      setLoading(true);

      api.post(`${process.env.REACT_APP_API_URL}api/mar-shopping/checkMobileNumber`, {
        number: `${phoneNumber}${val}`,
        locationId: process.env.REACT_APP_LOCATION_ID,
        numberPrefix: selectedCountry.dial_code,
      })
        .then(function (response) {
          console.log(response);
          if (response?.data?.ResultCode === 1) {
            console.log(response?.data);
            setPhoneAlreadyInUse(false);
            if(selectedCountry.name === 'Portugal' && (phoneNumber.charAt(0) !== '9' || !numbers.includes(phoneNumber.charAt(1)))){
              setPhoneNumber('');
              setSelectedInput('phoneNumber')
              setErrorNumberPrefix(true)
            }else{
              setErrorNumberPrefix(false)
              setError(false)
              //setSelectedInput('pin')
            }
            setError(false)
          } else if (response?.data?.Error === 'L23') {
            console.log(response?.data?.Description);
            setPhoneAlreadyInUse(true);
            if(selectedCountry.name === 'Portugal' && (phoneNumber.charAt(0) !== '9' || !numbers.includes(phoneNumber.charAt(1)))){
              setPhoneNumber('');
              setSelectedInput('phoneNumber')
              setErrorNumberPrefix(true)
            }else{
              setErrorNumberPrefix(false)
              setError(false)
              //setSelectedInput('pin')
            }
            setError(false)
            setErrorNumberPrefix(false)
          } else if (response?.data?.Error === 'L25') {
            console.log(response?.data?.Description);
            setPhoneAlreadyInUse(true);
            setError(true)
          } else {
            console.log(response?.data);
            console.log('Unknow Error');
            setPhoneAlreadyInUse(false);
            setSelectedInput('phoneNumber');
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        })
    }
    else if (phoneNumber.length < phoneNumberSize) setPhoneNumber(phoneNumber + val);

  };

  const handleSubmit = () => {
    const { type, size } = state;

    setLoading(true);
    setIsSubmitting(true);

    api.post(`${process.env.REACT_APP_API_URL}api/mar-shopping/assignLockerToUser`, {
      locationId: process.env.REACT_APP_LOCATION_ID,
      size: size,
      number: phoneNumber,
      pin: pin,
      name: phoneNumber,
      numberPrefix: selectedCountry.dial_code,
      type: type,
    })
      .then(function (response) {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          console.log(response?.data);
          navTo('/lokker-assigned', { state: { lockers: response?.data.Data, number: phoneNumber, pin: pin, numberPrefix: selectedCountry.dial_code } });
        }else if (response?.data?.Description === 'Invalid PIN') {
          console.log(response?.data?.Description);
          setPinAnimation('shake');
          setTimeout(() => {
            setPinAnimation('');
            setPin('');
          }, invalidInputAnimationDuration * 1000);
          setSelectedInput('pin');
        }else if (response?.data?.Description === 'No locker avaliable') {
          console.log(response?.data?.Description);
          navTo('/lokker-size', { state: { type: 0, accessibility: 0, checked: true }});
        }else {
          console.log(response?.data);
          console.log('Unknow Error');
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setIsSubmitting(false);
      });
  };

  const goBack = () => {
    navTo('/');
  };

  useEffect(() => {
    if (confirmPin.length === pinSize && !isConfirmPinValid()) {
      setConfirmPinAnimation('shake');
      setTimeout(() => {
        setConfirmPinAnimation('');
        setConfirmPin('');
      }, invalidInputAnimationDuration * 1000);
    }
  }, [confirmPin]);

  return (
    state &&
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-start position-relative">
      <LoadingModal isLoading={loading} />
      <Row
        style={{
          backgroundColor: colors['beije'].light,
          padding: '10vw 0 5vw 0'
        }}
        className="justify-content-center"
      >
        {selectedInput === 'phoneNumber' &&
          <>
            <Col xs="12" md="12" className="m-0">
              <SelectCountryPrefix 
                setPhoneNumberSize={setPhoneNumberSize}
                setPhoneNumber={setPhoneNumber}
                setSelectedInput={setSelectedInput}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
              />
            </Col>
            <Col xs="12" md="12" className="m-0">
              <Input
                label={t.messages['app.phoneNumber']}
                size={phoneNumberSize}
                type="number"
                value={phoneNumber}
                isFocused={selectedInput === 'phoneNumber'}
              />
            </Col>
          </>
        }
        {(selectedInput === 'pin' || selectedInput === 'confirmPin') &&
          <>
            <Col md="12" className="m-0">
              <Input
                label={phoneAlreadyInUse === false ? t.messages['app.createPin'] : t.messages['app.insertYourPin']}
                size={pinSize}
                type="password"
                value={pin}
                isFocused={selectedInput === 'pin'}
                style={{ animation: `${pinAnimation} ${invalidInputAnimationDuration}s ease-in-out` }}
              />
            </Col>
            <Col md="12" className="m-0">
              {phoneAlreadyInUse === false &&
                <Input
                  label={t.messages['app.confirmPin']}
                  size={pinSize}
                  type="password"
                  value={confirmPin}
                  isFocused={selectedInput === 'confirmPin'}
                  style={{ animation: `${confirmPinAnimation} ${invalidInputAnimationDuration}s ease-in-out` }}
                />
              }
            </Col>
            <Col md="12" className="m-0 mt-5">
              <span 
                style={{ fontSize: '2vw', fontWeight: '600', textDecoration: 'underline' }} 
                onClick={() => (
                  setSelectedInput('phoneNumber'),
                  setPin('')
                )}
              >
                {t.messages['app.previous']}
              </span>
            </Col>
            {selectedInput === 'pin' &&
              <Col md="12" className="m-0 mt-5">
                <span style={{ fontSize: '1.5vw', textDecoration: 'underline' }} onClick={() => navTo('/recover-pin')} >
                  {t.messages['app.forgotPin']}
                </span>
              </Col>
            }
          </>
        }
        {error &&
          <Col md="12" className="m-0 mt-2">
            <span style={{ fontSize: '2.5vw', fontWeight: '700', color: colors.error }}>
              {t.messages['app.alreadyHaveLocker']}
            </span>
          </Col>
        }
        {errorNumberPrefix &&
          <Col md="12" className="m-0 mt-2">
            <span style={{ fontSize: '2vw', color: colors.error }}>
              {t.messages['app.insertValidNumber']}
            </span>
          </Col>
        }
      </Row>
      <Row className="my-4 py-5">
        <Numpad handleChange={handleNumpadInput} />
      </Row>
      <Row className="m-0 px-5">
        {selectedInput === 'phoneNumber' &&
          <PrimaryButton
            text={t.messages['app.next']}
            onClick={() => setSelectedInput('pin')}
            isEnabled={isPhoneNumberValid() && !isSubmitting && !error && !errorNumberPrefix}
            color="yellow"
            txtColor="light"
          />
        }
        {(selectedInput === 'pin' || selectedInput === 'confirmPin') &&
          <PrimaryButton
            text={t.messages['app.confirm']}
            onClick={isSubmitting ? () => null : handleSubmit}
            isEnabled={validateInput() && !isSubmitting && !error && !errorNumberPrefix}
            color="yellow"
            txtColor="light"
          />
        }
        <PrimaryButton
          text={t.messages['app.cancel']}
          onClick={goBack}
          color="white"
          outline={true}
          border
          noBackground
        />
      </Row>
    </TransitionView>
  )
}