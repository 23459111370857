import React from 'react';
import { colors } from 'config/colors';
import { Col, Row } from 'reactstrap';
import { ReactComponent as XIcon } from 'assets/images/icons/v1/XIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/v1/DeleteIcon.svg';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: '600',
    fontSize: '3vw',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  keysWrapper: {
    width: '50%'
  },
  key: {
    padding: '40px',
    height: '150px'
  }
};

export const Numpad = ({ handleChange }) => {
  const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];

  return(
    <div style={styles.container} className="py-3">
      <Row style={styles.keysWrapper}>
        {keys.map((item, i) => 
          <Col
            xs="4"
            key={i}
            style={styles.key}
            onClick={() => handleChange(item)}
            className="d-flex justify-content-center align-items-center p-4"
          >
            <div 
              style={{
                width: '110px',
                height: '110px',
                border: `5px solid ${colors[process.env.REACT_APP_ZONE].medium}`,
                backgroundColor: colors['beije'].light
              }} 
              className="d-flex justify-content-center align-items-center"
            >
              {item === '*' 
              ? 
                  <XIcon height="20" width="20"/> 
              : 
                item === '#' ? 
                  <DeleteIcon height="20" width="20"/> 
                : 
                  <span>{item}</span>
              }
            </div>
          </Col>
        )}
      </Row>  
    </div>
  );
};