import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { LanguageModal } from 'components/common/LanguageModal';
import { SquareIconButton } from 'components/common/SquareIconButton';
import { useIntl } from 'react-intl';
import { ReactComponent as MarShopping } from 'assets/images/icons/v1/mar-shopping-red.svg';
import { ReactComponent as FlagPortugal } from 'assets/images/icons/v2/FlagPortugal.svg';
import { ReactComponent as FlagSpain } from 'assets/images/icons/v2/FlagSpain.svg';
import { ReactComponent as FlagUK } from 'assets/images/icons/v2/FlagUK.svg';
import { useSelector } from 'react-redux';
import { GetTopBarLabel } from 'helpers/utils';
import { useNavigate, useLocation } from 'react-router';
import { colors } from 'config/colors';
import { useRouterTransition } from 'hooks/useRouterTransition'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

export const TopNav = ({ counter }) => {
  const locale = useSelector(state => state.language);
  const layout = useSelector(state => state.layout);
  const t = useIntl();
  const { pathname } = useLocation();

  let navigate = useNavigate();
  let { handleTransition } = useRouterTransition(navigate);

  const [isOpen, setIsOpen] = useState(false);

  const getLangIcon = () => {
    if (locale === 'pt-PT') return <FlagPortugal />
    if (locale === 'es-ES') return <FlagSpain />
    if (locale === 'en-US') return <FlagUK />
  };

  const goHome = () => {
    navTo('/');
  };

  const navTo = (url, state) => {
    handleTransition (url, state)
  };

  return (
    <Row
      className="p-4 align-items-center"
      style={{
        backgroundColor: !layout.homepage ? colors.white : colors[process.env.REACT_APP_ZONE].medium,
        color: !layout.homepage ? colors.white : colors['beije'].medium
      }}
    >
      <Col xs="2" onClick={goHome}>
        <SquareIconButton icon={<MarShopping height="110" />} />
      </Col>
      {counter &&
      <Col xs="1" className="d-flex justify-content-center">
        <CountdownCircleTimer
          isPlaying
          key={counter}
          duration={counter}
          colors={colors[process.env.REACT_APP_ZONE].medium}
          onComplete={goHome}
          size="90"
          strokeWidth="5"
        >
          {({ remainingTime }) => <span style={{ color: colors.black }}>{(remainingTime)}s</span> }
        </CountdownCircleTimer>
      </Col>
      }
      <Col xs={ counter ? '7' : '8' }>
        <span
          style={{
            fontSize: '3vw',
            fontWeight: '600',
            color: !layout.homepage ? colors['grey'].medium : colors.white
          }}
        >
          <FormattedMessage id={GetTopBarLabel(pathname)} defaultMessage="os" />
        </span>
      </Col>
      <Col xs="2">
        <SquareIconButton text={t.messages['app.language']} icon={getLangIcon()} onClick={() => setIsOpen(true)} homepage={layout.homepage} />
      </Col>
      <LanguageModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </Row>
  );
};