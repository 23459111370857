import React from 'react';
import { colors } from 'config/colors';
import { disabledOpacity, borderWidth } from 'config/appConfig';

const styles = {
  container: {
    fontWeight: '600',
    fontSize: '3vw',
    transition: '0.3s ease-in-out',
    padding: '3vw'
  }
};

export const PrimaryButton = ({ text, onClick, isEnabled = true, color = 'yellow', border = false, txtColor, noBackground }) => {

  return(
    <div
      style={{
        ...styles.container,
        backgroundColor: noBackground ? 'transparent' : colors[process.env.REACT_APP_ZONE].medium,
        opacity: `${isEnabled ? '1' : disabledOpacity}`,
        pointerEvents: `${isEnabled ? 'auto' : 'none'}`,
        border: `${border !== false ? `${borderWidth} solid ${colors[process.env.REACT_APP_ZONE].medium}` : 'none'}`
      }}
      onClick={() => onClick()}
      className="my-2 py-3"
    >
      <span style={{color: txtColor === 'light' ? colors.white : '' }}>{text}</span>
    </div>
  );
};