import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { MenuButton } from 'components/common/MenuButton';
import { ReactComponent as Helmet } from 'assets/images/icons/v2/Helmet.svg';
import { ReactComponent as Bicicle } from 'assets/images/icons/v2/Bicicle.svg';
import { ReactComponent as Scooter } from 'assets/images/icons/v2/Scooter.svg';
import { disabledOpacity } from 'config/appConfig';
import { SecondaryNav } from 'components/common/SecondaryNav';
import api from 'helpers/api';
import { LoadingModal } from 'components/common/LoadingModal';

export const GreenZoneLokkerSize = () => {
  const t = useIntl();
  let navigate = useNavigate();
  const { state } = useLocation();
  const iconSize = '70px'
  let { transitionType, handleTransition } = useRouterTransition(navigate);

  const [lockers, setLockers] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const navTo = (url, state) =>{
    handleTransition (url, state)
  };

  const normalSizeSLockersAvailable = () => {
    return lockers.filter(function (locker) {
      return (
        locker.enabled === 1 && 
        locker.status === 0 && 
        locker.locker_metra_status === 2 &&
        locker.lockerType === state.type &&
        locker.size === 'S' &&
        ( state.accessibility === 0 ? (locker.accessibility === 0 || locker.accessibility === 1) : locker.accessibility === 1 )
      )
    });
  };

  const normalSizeMLockersAvailable = () => {
    return lockers.filter(function (locker) {
      return (
        locker.enabled === 1 && 
        locker.status === 0 && 
        locker.locker_metra_status === 2 && 
        locker.lockerType === state.type && 
        locker.size === 'M' && 
        ( state.accessibility === 0 ? (locker.accessibility === 0 || locker.accessibility === 1) : locker.accessibility === 1 )
      )
    });
  };

  const normalSizeLLockersAvailable = () => {
    return lockers.filter(function (locker) {
      return (
        locker.enabled === 1 && 
        locker.status === 0 && 
        locker.locker_metra_status === 2 && 
        locker.lockerType === state.type && 
        locker.size === 'L' && 
        ( state.accessibility === 0 ? (locker.accessibility === 0 || locker.accessibility === 1) : locker.accessibility === 1 )
      )
    });
  };

  useEffect(() => {
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/lockersByPlace/${process.env.REACT_APP_LOCATION_ID}`)
      .then(function (response) {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          setLockers(response?.data?.Data?.Lockers)
        } else {
          console.log(response?.data?.Result);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  },[]);

  return(
    state && state.checked &&
    <TransitionView animation="fade-down" transitionType={transitionType} className="position-relative px-5">
      <LoadingModal isLoading={loading} />
      <SecondaryNav secNavText="app.pickLockerSize" navToURL="/" state={{ state: {...state } }}/>
      <Row className="m-0">
        <MenuButton
          text={t.messages['app.helmet']}
          subtext={`${normalSizeSLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row>
              <Col xs="4"><Helmet height={iconSize} width={iconSize} style={{opacity: normalSizeSLockersAvailable().length !== 0 ? '1' : disabledOpacity}} /></Col>
            </Row>
          }
          onClick={() => navTo('/create-pin', { state : { ...state, size: 'S'}})}
          disabled={normalSizeSLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row className="m-0 my-5">
        <MenuButton
          text={t.messages['app.scooter']}
          subtext={`${normalSizeMLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row>
              <Col xs="4"><Scooter height={iconSize} width={iconSize} style={{opacity: normalSizeMLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/></Col>
            </Row>
          }
          onClick={() => navTo('/create-pin', { state : { ...state, size: 'M'}})}
          disabled={normalSizeMLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.bicycle']}
          subtext={`${normalSizeLLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row>
              <Col xs="4"><Bicicle height={iconSize} width={iconSize} style={{opacity: normalSizeLLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/></Col>
            </Row>
          }
          onClick={() => navTo('/create-pin', { state : { ...state, size: 'L'}})}
          disabled={normalSizeLLockersAvailable().length !== 0 ? false : true}
        />
      </Row>
    </TransitionView>
  )
}