import React, { useState } from 'react';
import { Row } from 'reactstrap';
import { useNavigate } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { Numpad } from 'components/common/numpad/Numpad';
import { Input } from 'components/common/numpad/Input';
import { useIntl } from 'react-intl';
import { PrimaryButton } from 'components/common/PrimaryButton';
import { LoadingModal } from 'components/common/LoadingModal';
import api from 'helpers/api';

const styles = {
  errorTxt: {
    fontSize: '2vw',
    fontWeight: '300',
    color: 'red'
  },
  rowPadding: {
    padding: '10vw 0'
  }
}

export const GreenZoneCollectAccessPin = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);

  const [selectedInput, setSelectedInput] = useState('pin');
  const [pin, setPin] = useState('');
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [pinAnimation, setPinAnimation] = useState('');

  const invalidInputAnimationDuration = 0.7;

  const pinSize = 6;


  const handleChangePin = (val) => {
    // delete last char from pin
    if(val === '#'){
      if(pin.length === 0) setSelectedInput('pin');
      else setPin(pin.slice(0, -1));
    }
    // add char to pin
    // if last char also go to next input
    else if(pin.length === pinSize - 1){
      setPin(pin + val);
    }
    else if(pin.length < pinSize) setPin(pin + val);
  };

  const navTo = (url, state) =>{
    handleTransition (url, state)
  };

  const handleNumpadInput = (val) => {
    // when user presses asterisk clear everything
    if(val === '*'){
      setPin('');
      setSelectedInput('pin');
    }
    else{
      // handle specific input
      switch (selectedInput) {
        case 'pin':
          handleChangePin(val)
          break;
        default:
          break;
      }
    }
  };

  const isPinValid = () => {
    const pattern = /\d{6}/;
    if(pin.match(pattern)){
      // is valid
      return true;
    }
  };

  const validateInput = () => {
    if(isPinValid()){
      // input is valid
      return true;
    } else{
      // input is invalid
      return false;
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/deliveryDetailsWithPin?pin=${pin}`)
    .then(function (response) {
      console.log(response);
      if (response?.data?.ResultCode === 1) {
        console.log(response?.data)
        navTo('/collect-object', { state: { locker: response?.data?.Data?.assignedLocker, pin: pin } });
      } else {
        console.log(response?.data);
        setPinAnimation('shake');
        setTimeout(() => {
          setPin('');
          setPinAnimation('');
        }, invalidInputAnimationDuration * 1000);
      }
    })
    .catch(function (err) {
      console.log(err);
      setPinAnimation('shake');
      setTimeout(() => {
        setPin('');
        setPinAnimation('');
      }, invalidInputAnimationDuration * 1000);
    })
    .finally(() => {
      setIsSubmitting(false);
      setLoading(false);
    });

  };

  const goBack = () => {
    navTo('/pick-and-collect');
  };

  return(
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-end position-relative">
      <LoadingModal isLoading={loading} />
      <Row style={styles.rowPadding} className="m-0">
        <Input
          label={t.messages['app.insertSMSCode']}
          size={pinSize}
          type="number"
          value={pin}
          isFocused={selectedInput === 'pin'}
          style={{animation: `${pinAnimation} ${invalidInputAnimationDuration}s ease-in-out`}}
        />
      </Row>
      <Row style={styles.rowPadding} className="m-0">
        <Numpad handleChange={handleNumpadInput} />
      </Row>
      <Row className="m-0">
        <PrimaryButton
          text={t.messages['app.confirm']}
          onClick={isSubmitting ? () => null : handleSubmit}
          isEnabled={validateInput() && !isSubmitting}
          color="yellow"
        />
        <PrimaryButton
          text={t.messages['app.cancel']}
          onClick={goBack}
          color="white"
          outline={true}
          border="lightGrey"
        />
      </Row>
    </TransitionView>
  )
}