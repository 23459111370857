import React, { useEffect, useState } from 'react';
import { TopNav } from 'components/layout/TopNav';
import { colors } from 'config/colors';
import { useLocation } from 'react-router';
import { ReactComponent as Background } from 'assets/images/background/background.svg';
import { backgroundImageExclusions } from 'config/appConfig';
import { useDispatch, useSelector } from 'react-redux';
import Socket from 'helpers/socket';
import { getRecentToken } from "redux/actions/token"
import { routes } from 'config/appConfig';
import { ReactComponent as PoweredByLokk } from 'assets/images/footer/powered.svg'
import { FaqsModal } from 'components/common/FaqsModal';
import { ReactComponent as Help } from 'assets/images/icons/v2/Help.svg';

export const AppLayout = ({ children }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation();
  const zone = pathname.split('/')[1]
  const credentials = useSelector(state => state.credentials);
  const layout = useSelector(state => state.layout);

  const [delay, setDelay] = useState(false)
  const [faqOpen, setFaqOpen] = useState(false);

  var timer = null;

  // event listeners to detect user interaction with the screen
  const detectActivity = () => {
    document.addEventListener('mousedown', resetInactivityTimer);
    document.addEventListener('mousemove', resetInactivityTimer);
    document.addEventListener('touchstart', resetInactivityTimer);
    document.addEventListener('scroll', resetInactivityTimer);
    document.addEventListener('keydown', resetInactivityTimer);
  };
  
  // set the inactivity timer to navigate to the home view after x seconds have passed
  const resetInactivityTimer = () => {
    let delay = 120;
    setDelay(false)
    routes.map((item)=>{
      if(zone === item.path){
        delay = item.inativityTime;
        setDelay(delay)
      }
    })
    
    if(timer !== null) clearTimeout(timer);
    
    timer = setTimeout(() => {
      
    }, delay * 1000);
    // TODO: clear any stored user data
  };

  // check if QR Code have most recent token
  const checkTokenTimer = () => {
    dispatch(getRecentToken(credentials.token))
  };

  const refreshUrlToken = (data) => {
    dispatch({type: 'REFRESH_TOKEN', payload: data })
  }

  //initiate the inactivity detection on component mount
  useEffect(() => {
    resetInactivityTimer();
    detectActivity();
  },[zone]);


  useEffect(() => {
    const interval = setInterval(() => {
      checkTokenTimer();
    }, process.env.REACT_APP_CHECK_TOKEN_TIMER * 1000);
  
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  })

  return (
    <div className="vh-100" style={{ overflowY: 'hidden', overflowX: 'hidden'}}>
      <Socket handleLockerStatusUpdate={refreshUrlToken} token={process.env.REACT_APP_API_TOKEN}/>
      <header 
        style={{ 
          height: '10%' 
        }}
        >
        <TopNav counter={delay}/>
      </header>
      <main 
        style={{ 
          height: '85%' 
        }}
      >
        {!backgroundImageExclusions.includes(zone) &&
          <Background 
            fill={!layout.homepage ? colors[process.env.REACT_APP_ZONE].light : colors['beije'].light}
            width="100%" 
            height="100%" 
            style={{ 
              position: 'absolute', 
              top: '20%', 
              right: '0', 
              zIndex: 0 ,
            }} 
          />
        }
        <div className="h-100" style={{ position: 'relative', top: '0',  right: '0', zIndex: 1 }}>
          {children}
        </div>
      </main>
      <footer
        className="d-flex align-items-center justify-content-between px-5"
        style={{ 
          height: '5%' 
        }}
      >
        <span onClick={() => setFaqOpen(true)} style={{ zIndex: 10 }}>
          <Help height="45" width="45" />
        </span>
        <PoweredByLokk width="190" className="powered-by-lokk-logo" fill={colors.darkBlue} />
      </footer>
      <FaqsModal isOpen={faqOpen} setIsOpen={setFaqOpen} />
    </div>
  );
};