import React from 'react';
import { colors } from 'config/colors'
import { useLocation } from 'react-router';

export const LockerIcon = ({ lockerNumber, size, icon }) => {

  const { pathname } = useLocation();

  const containerSize = '250';
  const containerBorder = '4';
  
  const styles = {
    container: {
      border: `${containerBorder}px solid ${colors[process.env.REACT_APP_ZONE].medium}`,
      height: `${containerSize}px`,
      width: `${containerSize}px`,
      margin: 'auto',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.white
    },
    lockerNumber: {
      fontSize: '4.5vw',
      fontWeight: '600'
    },
    topRow: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'middle',
      padding: '15px',
      paddingLeft: '15px',
      paddingRight: '15px',
      fontWeight: '600',
      fontSize: '2.5vw'
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'middle'
    },
    icon: {
      height: '24px'
    },
    pulsingBorder: {
      border: `1px solid ${colors[process.env.REACT_APP_ZONE].medium}`,
      height: `${containerSize}px`,
      width: `${containerSize}px`,
      position: 'absolute',
      top: `${0 - containerBorder}px`,
      left: `${0 - containerBorder}px`,
    },
    pulsingBorder1: {
      animation: 'locker-icon-pulse1 1.5s infinite ease-in-out',
    },
    pulsingBorder2: {
      animation: 'locker-icon-pulse2 1.5s infinite ease-in-out',
    }
  }

  return (
    <div style={styles.container} className="p-4 my-4">
      <div style={{ ...styles.pulsingBorder, ...styles.pulsingBorder1 }}></div>
      <div style={{ ...styles.pulsingBorder, ...styles.pulsingBorder2 }}></div>
      <div style={styles.topRow}>
        {/* <span style={styles.iconWrapper}><img src={icon} style={styles.icon} /></span> */}
        <span>{icon}</span>
      </div>
      <span style={styles.lockerNumber}>{lockerNumber}</span>
    </div>
  );
};