import * as React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { NotFoundView } from 'views/not-found';
import { AppLayout } from 'layouts/AppLayout';
//RedZone------------------------------------------------------------------
import { RedZoneHome } from 'views/red-zone/home';
import { RedZoneLokkerType } from 'views/red-zone/lokker-type';
import { RedZoneLokkerSize } from 'views/red-zone/lokker-size';
import { RedZoneMyLokkers } from 'views/red-zone/my-lokkers';
import { RedZoneCreatePin } from 'views/red-zone/create-pin';
import { RedZoneLockerAssigned } from 'views/red-zone/locker-assigned';
import { RedZoneAccessibleLokkers } from 'views/red-zone/accessible-lokkers';
import { RedZoneReleaseLokker } from 'views/red-zone/release-lokker';
import { RedZoneLokkerReleased } from 'views/red-zone/lokker-released';
import { RedZoneLokkerOpened } from 'views/red-zone/lokker-opened';
import { RedZoneAccessMyLokkers } from 'views/red-zone/access-my-lokkers';
import { RedZonePickNCollect } from 'views/red-zone/pick-and-collect';
import { RedZoneDepositSize } from 'views/red-zone/deposit-size';
import { RedZoneDepositCreatePin } from 'views/red-zone/deposit-create-pin';
import { RedZoneDepositConfirm } from 'views/red-zone/deposit-confirm';
import { RedZoneDepositLokkerOpened } from 'views/red-zone/deposit-lokker-opened';
import { RedZoneCollectAccessPin } from 'views/red-zone/collect-access-pin';
import { RedZoneCollectObject } from 'views/red-zone/collect-object';
import { RedZoneRecoverPin} from 'views/red-zone/recover-pin';
import { RedZoneSmsSent} from 'views/red-zone/sms-sent';
import { RedZoneTermsAndConditions} from 'views/red-zone/terms-conditions';
//GreenZone------------------------------------------------------------------
import { GreenZoneHome } from 'views/green-zone/home';
//import { GreenZoneLokkerType } from 'views/green-zone/lokker-type';
import { GreenZoneLokkerSize } from 'views/green-zone/lokker-size';
import { GreenZoneMyLokkers } from 'views/green-zone/my-lokkers';
import { GreenZoneCreatePin } from 'views/green-zone/create-pin';
import { GreenZoneLockerAssigned } from 'views/green-zone/locker-assigned';
import { GreenZoneAccessibleLokkers } from 'views/green-zone/accessible-lokkers';
import { GreenZoneReleaseLokker } from 'views/green-zone/release-lokker';
import { GreenZoneLokkerReleased } from 'views/green-zone/lokker-released';
import { GreenZoneLokkerOpened } from 'views/green-zone/lokker-opened';
import { GreenZoneAccessMyLokkers } from 'views/green-zone/access-my-lokkers';
import { GreenZonePickNCollect } from 'views/green-zone/pick-and-collect';
import { GreenZoneDepositSize } from 'views/green-zone/deposit-size';
import { GreenZoneDepositCreatePin } from 'views/green-zone/deposit-create-pin';
import { GreenZoneDepositConfirm } from 'views/green-zone/deposit-confirm';
import { GreenZoneDepositLokkerOpened } from 'views/green-zone/deposit-lokker-opened';
import { GreenZoneCollectAccessPin } from 'views/green-zone/collect-access-pin';
import { GreenZoneCollectObject } from 'views/green-zone/collect-object';
import { GreenZoneRecoverPin} from 'views/green-zone/recover-pin';
import { GreenZoneSmsSent} from 'views/green-zone/sms-sent';
import { GreenZoneTermsAndConditions} from 'views/green-zone/terms-conditions';
//DeliveryZone------------------------------------------------------------------
import { DeliveryZoneHome } from 'views/delivery-zone/home';
import { DeliveryZoneColaboratorAccess } from 'views/delivery-zone/colaborator-access';
import { DeliveryZoneCollectObject } from 'views/delivery-zone/collect-object';
import { DeliveryZoneLokkerSize } from 'views/delivery-zone/lokker-size';
import { DeliveryZoneClientNumber } from 'views/delivery-zone/client-number';
import { DeliveryZoneDeliveryConfirm } from 'views/delivery-zone/delivery-confirm';
import { DeliveryZoneDepositLokkerOpened } from 'views/delivery-zone/deposit-lokker-opened';

import { AxiosInterceptor } from "helpers/api";

export const AppRouter = () => {

  return(
    <BrowserRouter>
    <AxiosInterceptor>
      <AppLayout>
        <Routes>
          <Route element={<Navigate to="/" />} />
          {process.env.REACT_APP_ZONE === 'red-zone' && 
          <>
            <Route index element={<RedZoneHome />}/>
            <Route path="lokker-type" element={<RedZoneLokkerType />} />
            <Route path="lokker-size" element={<RedZoneLokkerSize />} />
            <Route path="access-my-lokkers" element={<RedZoneAccessMyLokkers />} />
            <Route path="my-lokkers" element={<RedZoneMyLokkers />} />
            <Route path="create-pin" element={<RedZoneCreatePin />} />
            <Route path="lokker-assigned" element={<RedZoneLockerAssigned />} />
            <Route path="accessible-lokker" element={<RedZoneAccessibleLokkers />} />
            <Route path="release-lokker" element={<RedZoneReleaseLokker />} />
            <Route path="lokker-released" element={<RedZoneLokkerReleased />} />
            <Route path="lokker-opened" element={<RedZoneLokkerOpened />} />
            <Route path="pick-and-collect" element={<RedZonePickNCollect />} />
            <Route path="deposit-size" element={<RedZoneDepositSize />} />
            <Route path="deposit-create-pin" element={<RedZoneDepositCreatePin />} />
            <Route path="deposit-confirm" element={<RedZoneDepositConfirm />} />
            <Route path="deposit-lokker-opened" element={<RedZoneDepositLokkerOpened />} />
            <Route path="collect-access-pin" element={<RedZoneCollectAccessPin />} />
            <Route path="collect-object" element={<RedZoneCollectObject />} />
            <Route path="recover-pin" element={<RedZoneRecoverPin />} />
            <Route path="sms-sent" element={<RedZoneSmsSent />} />
            <Route path="terms-conditions" element={<RedZoneTermsAndConditions />} />
          </>
          }
          {process.env.REACT_APP_ZONE === 'green-zone' && 
          <>
            <Route index element={<GreenZoneHome />}/>
            <Route path="lokker-size" element={<GreenZoneLokkerSize />} />
            <Route path="access-my-lokkers" element={<GreenZoneAccessMyLokkers />} />
            <Route path="my-lokkers" element={<GreenZoneMyLokkers />} />
            <Route path="create-pin" element={<GreenZoneCreatePin />} />
            <Route path="lokker-assigned" element={<GreenZoneLockerAssigned />} />
            <Route path="accessible-lokker" element={<GreenZoneAccessibleLokkers />} />
            <Route path="release-lokker" element={<GreenZoneReleaseLokker />} />
            <Route path="lokker-released" element={<GreenZoneLokkerReleased />} />
            <Route path="lokker-opened" element={<GreenZoneLokkerOpened />} />
            <Route path="pick-and-collect" element={<GreenZonePickNCollect />} />
            <Route path="deposit-size" element={<GreenZoneDepositSize />} />
            <Route path="deposit-create-pin" element={<GreenZoneDepositCreatePin />} />
            <Route path="deposit-confirm" element={<GreenZoneDepositConfirm />} />
            <Route path="deposit-lokker-opened" element={<GreenZoneDepositLokkerOpened />} />
            <Route path="collect-access-pin" element={<GreenZoneCollectAccessPin />} />
            <Route path="collect-object" element={<GreenZoneCollectObject />} />
            <Route path="recover-pin" element={<GreenZoneRecoverPin />} />
            <Route path="sms-sent" element={<GreenZoneSmsSent />} />
            <Route path="terms-conditions" element={<GreenZoneTermsAndConditions />} />
          </>
          }
         
         {process.env.REACT_APP_ZONE === 'delivery-zone' && 
          <>
            <Route index element={<DeliveryZoneHome />}/>
            <Route path="colaborator-access" element={<DeliveryZoneColaboratorAccess />} />
            <Route path="collect-object" element={<DeliveryZoneCollectObject />} />
            <Route path="lokker-size" element={<DeliveryZoneLokkerSize />} />
            <Route path="client-number" element={<DeliveryZoneClientNumber />} />
            <Route path="delivery-confirm" element={<DeliveryZoneDeliveryConfirm />} />
            <Route path="deposit-lokker-opened" element={<DeliveryZoneDepositLokkerOpened />} />
          </>
          }

          {/* Not Found Route */}
          <Route path="*" element={<NotFoundView />} />
          {/* Not Found Route */}

        </Routes>
      </AppLayout>
      </AxiosInterceptor>
    </BrowserRouter>
  );
};