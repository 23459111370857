import React from 'react';
import { Row, Col } from 'reactstrap';
import { colors } from 'config/colors';
import { borderWidth }from 'config/appConfig';

export const LanguageModalButton = ({ icon, text, onClick }) => {

  const styles = {
    container: {
      border: `${borderWidth} solid ${colors[process.env.REACT_APP_ZONE].medium}`,
      backgroundColor: "#fff",
      margin: '2vw 0'
    },
    text: {
      fontSize: '2.5vw',
      fontWeight: '600',
      letterSpacing: '0.25vw'
    },
  }

  return(
    <Row style={styles.container} className="px-4 py-4" onClick={onClick}>
      <Col xs="6" className="d-flex justify-content-center align-items-center">
        <span>
          {icon}
        </span>
      </Col>
      <Col xs="6" className="d-flex justify-content-start align-items-center">
        <span style={styles.text}>
          {text}
        </span>
      </Col>
    </Row>
  )
}